import { Link } from 'gatsby';
import * as styles from './styles.module.scss';
import { IReference } from '../../types/commons';
import { formatInternalLink } from '../../utils/linksUtils';

interface IDisclaimerProps {
  data: {
    isActive: boolean
    text: string
    linkType: string
    internalLinkReference: {
      reference: IReference
    }
    externalLinkReference: {
      href: string
    }
    internalLinkId?: string;
  }
}

export default function Disclaimer({ data }: IDisclaimerProps) {
  const isActive = data.isActive
  const text = data.text
  const linkType = data.linkType
  const hasNoLink = linkType === "noLink"
  const isInternalLink = linkType === "isInternalLink"
  const isExternalLink = linkType === "isExternalLink"
  const internalLinkId = data.internalLinkId;


  if (!isActive) {
    return null;
  }

  const internalLink = formatInternalLink(data.internalLinkReference.reference);
  const formattedInternalLink = internalLinkId ? `${internalLink}#${internalLinkId}` : internalLink;


  return (
    <div className={styles.disclaimer}>
      {!!hasNoLink && (
        <p>{text}</p>
      )}
      {!!isInternalLink && (
        <Link to={formattedInternalLink}>{text}</Link>
      )}
      {!!isExternalLink && (
        <a href={data.externalLinkReference.href}>{text}</a>
      )}
    </div>
  )
}
